//--------------------------------------------
//定義
//--------------------------------------------
@use "./variables" as *;

//--------------------------------------------
//ヘッダー
//--------------------------------------------
.header{
	background: url(/assets/img/company/concept/bg_header.jpg) no-repeat center bottom / cover;
}
//--------------------------------------------
//コンテンツ
//--------------------------------------------
.concept{
	&:not(:last-child){
		margin-bottom: 90px;
	}
}
.section__title{
	&--large{
		font-size: 6rem;
		letter-spacing: 3px;
		@media #{$sp}{
			font-size: 15vw;
		}
	}
}
.section{
	&__sub{
		font-size: 2.4rem;
		@media #{$sp}{
			font-size: 6vw;
		}
	}
}
.page-introduction-strong {
	text-align: center;
	font-weight: bold;
	margin-bottom: 1em;
}
.page-introduction {
	text-align: center;
	margin-bottom: 1em;
	@media #{$sp}{
		text-align: left;
	}
	a {
		color:$orange;
		text-decoration: underline;
		&:hover {
			text-decoration: none;
		}
	}
}
.page-introduction-link {
	@media #{$sp}{
		text-align: center;
	}
}


