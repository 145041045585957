@charset "UTF-8";
@import url(https://fonts.googleapis.com/css?family=Sawarabi+Mincho);
//----------------------------------------------------------
//   全体で使用する変数セット
//----------------------------------------------------------
//基本コンテンツ幅
$width: 1000px;

//ブレイクポイント　メインビジュアル
$mv: "screen and (max-width:1440px)";

//ブレイクポイント　スマホ・タブレット共通
$sp_tb: "screen and (max-width:1023px)";

//ブレイクポイント スマホ縦向き
$sp: "screen and (max-width:767px)";

//ブレイクポイント スマホ横向き～タブレット縦
$tb: "screen and (min-width:768px) and (max-width:1023px)";

//ブレイクポイント スマホ横向き～タブレット縦
$pc: "screen and (min-width:768px)";

//ブレイクポイント 1000px以下
$max1000: "screen and (max-width:1000px)";

//黒
$black: #000;

//白
$white: #fff;

//緑
$green: #6BCC38;

//明るい緑
$l_green: #6ada3a;

//黄色
$yellow: #ffde27;

//オレンジ
$orange: #F2A62B;

//レッド
$red: #F46D45;

//青
$blue: #2075C9;

//明るい青
$blue-light: #3B52CE;

//明るい青
$blue-light2: #13C1C7;

//暗いベージュ
$beige-dark: #f5f1ea;

//明るいベージュ
$beige-light: #fdf9f2;

//暗いブラウン
$brown-dark: #9d917d;

//グレー
$gray: #e8e8e8;

//明るいグレー
$gray-light: #f0f0f0;

//グレー
$gray-text: #CFCFCF;

//線のグレー
$gray-border: #d2d2d2;

//紫
$purple: #DD4E9B;

//フォントファミリー
$font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
$font-family-eng: 'EB Garamond',"游明朝体", "YuMincho", "游明朝", "Yu Mincho","Sawarabi Mincho","ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN W3", HiraMinProN-W3, "ヒラギノ明朝 ProN", "Hiragino Mincho ProN",serif;
$font-mont: 'Montserrat', sans-serif;
//IEハック用
$hack-ie11: "*::-ms-backdrop";
@mixin hack-ie($hack-ver:'') {
    // * IE8 hack
    @if $hack-ver == "ie8" {
        @media \0screen {
            @content;
        }
    }
    // * IE9-10 hack(add propary \9)
    @else if $hack-ver == "ie9-10" {
        @media all and (min-width:0\0) {
            @content;
        }
    }
    // * IE10 hack(add propary \9)
    @else if $hack-ver == "ie10" {
        @media all and (-ms-high-contrast:none\0) {
            @content;
        }
    }
    // * IE11 hack(add selector *::-ms-backdrop)
    @else if $hack-ver == "ie11" {
        @media all and (-ms-high-contrast:none\0) {
            @content;
        }
    }
}

//画面サイズによって文字サイズ変更処理
@mixin fluid-type($properties, $min-vw, $max-vw, $min-value, $max-value) {
  @each $property in $properties {
    #{$property}: $min-value;
  }

  @media screen and (min-width: $min-vw) {
    @each $property in $properties {
      #{$property}: calc(#{$min-value} + #{strip-unit($max-value - $min-value)} * (100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)});
    }
  }

  @media screen and (min-width: $max-vw) {
    @each $property in $properties {
      #{$property}: $max-value;
    }
  }
}

@function strip-unit($value) {
  @return $value / ($value * 0 + 1);
}

//キーフレーム
@mixin keyframes($animation-name) {
	@-webkit-keyframes #{$animation-name} {
		@content;
	}
	@-moz-keyframes #{$animation-name} {
		@content;
	}
	@keyframes #{$animation-name} {
		@content;
	}
}

//アニメーション
@mixin animation($animation-name) {
  -webkit-animation: $animation-name;
  -moz-animation: $animation-name;
  animation: $animation-name;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  animation-fill-mode: both;
}

//エディタースタイル
@mixin editor{
    p{
        &:not(:last-child){
            // margin-bottom: 50px;
        }
    }
    a{
        color: $orange;
        text-decoration: underline;
        &:hover{
            text-decoration: none;
        }
        &[target= _blank]{
            background: url(/assets/img/common/icon_blank.svg) no-repeat right center / 11px 9px;
            padding-right: 15px;
            &[href$=".pdf"]{
                background: url(/assets/img/common/icon_file.png) no-repeat right center / 8px 10px;
                padding-right: 12px;
            }
        }
    }
    h1{
        font-size: 3rem;
        position: relative;
        margin-bottom: 60px;
		padding-left: 20px;
		border-left: 5px solid $orange;
        line-height: 1.2;
        clear: both;
		color: $orange;
		// font-family: $font-family-eng;
		font-weight: 700;
    }
    h2{
        font-size: 2.6rem;
        line-height: 1.2;
        color: $orange;
		// font-family: $font-family-eng;
		font-weight: 700;
        margin-bottom: 25px;
        clear: both;
    }
    h3{
        font-size: 2rem;
        line-height: 1.2;
        margin-bottom: 25px;
        clear: both;
		color: $orange;
    }
    ol,ul{
        margin-left: 20px;
        &:not(:last-child){
            margin-bottom: 30px;
        }
    }
    .alignright{
        float: right;
        margin-left: 20px;
    }
    .alignleft{
        float: left;
        margin-right: 20px;
    }
    .aligncenter{
        display: block;
        margin: 0 auto 30px auto;
    }

    .clm_2 {
        display: flex;
        flex-wrap: wrap;
        > div {
            width: 47.5%;
            margin-bottom: 20px;
            &:not(:nth-child(2n+2)){
                margin-right: 5%;
            }
            img {
                width: 100%;
                height: auto;
            }
        }
    }
    .clm_3 {
        display: flex;
        flex-wrap: wrap;
        > div {
            width: 30%;
            margin-bottom: 20px;
            @media #{$sp}{
                width: 47.5%;
            }
            &:not(:nth-child(3n+3)){
                @media #{$pc}{
                    margin-right: 5%;
                }
            }
            &:not(:nth-child(2n+2)){
                @media #{$sp}{
                    margin-right: 5%;
                }
            }
            img {
                width: 100%;
                height: auto;
            }
        }
    }



}

// レティーナ対応
// -------------------------
$scr_retina: "screen and (-webkit-min-device-pixel-ratio: 2),only screen and (min--moz-device-pixel-ratio: 2),only screen and (-o-min-device-pixel-ratio: 2/1),only screen and ( min-device-pixel-ratio: 2),only screen and (min-resolution: 192dpi),only screen and (min-resolution: 2dppx)";

